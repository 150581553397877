import githubLogo from '../images/icons/github-icon-64b.png';
import fwaLogo from '../images/icons/fwa-icon-64b.png';
import npmLogo from '../images/icons/npm-icon-64b.png';
import awwwardsLogo from '../images/icons/awwwards-icon-64b.png';
import devtoLogo from '../images/icons/devto-icon-64b.png';
import mediumLogo from '../images/icons/medium-icon-64b.png';
import facebookLogo from '../images/icons/facebook-icon-64b.png';
import twitterLogo from '../images/icons/twitter-icon-64b.png';
import linkedinLogo from '../images/icons/linkedin-icon-64b.png';

export const FOOTER_LINKS = [
  {
    img: githubLogo,
    alt: 'Github',
    src: 'https://github.com/Jam3?type=source'
  },
  {
    img: fwaLogo,
    alt: 'fwa',
    src: 'https://thefwa.com/profiles/jam3'
  },
  {
    img: npmLogo,
    alt: 'NPM',
    src: 'https://www.npmjs.com/org/jam3'
  },
  {
    img: awwwardsLogo,
    alt: 'awwwards',
    src: 'https://www.awwwards.com/Jam3/'
  },
  {
    img: devtoLogo,
    alt: 'dev.to',
    src: 'https://dev.to/jam3'
  },
  {
    img: mediumLogo,
    alt: 'Medium',
    src: 'https://medium.com/@Jam3'
  },
  {
    img: facebookLogo,
    alt: 'Facebook',
    src: 'https://www.facebook.com/weareJam3'
  },
  {
    img: twitterLogo,
    alt: 'Twitter',
    src: 'https://twitter.com/jam3'
  },
  {
    img: linkedinLogo,
    alt: 'Linkedin',
    src: 'https://www.linkedin.com/company/jam3/'
  }
];
