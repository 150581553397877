import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
// Styles
import '../../styles/main.scss';
// Data
import { FOOTER_LINKS } from '../../data/footerLinks';
// Images
import jamLogo from '../../images/jamlogoanim.gif';
import jamLogoStatic from '../../images/jamlogo-static-b.png';
import UpArrowSVG from '../../images/svgs/up-arrow.svg';
//for loading fonts in with webpack
import MatterLight from '../../styles/fonts/Matter-Light.woff2';
import MatterRegular from '../../styles/fonts/Matter-Regular.woff2';
import MatterMedium from '../../styles/fonts/Matter-Medium.woff2';
import MatterSemiBold from '../../styles/fonts/Matter-SemiBold.woff2';
import MatterBold from '../../styles/fonts/Matter-Bold.woff2';
import MatterHeavy from '../../styles/fonts/Matter-Heavy.woff2';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Matter';
    src: url("${MatterLight}")  format('woff2');
    font-weight: 100;
  }
  @font-face {
    font-family: 'Matter';
    src: url("${MatterRegular}")  format('woff2');
    font-weight: 200;
  }
  @font-face {
    font-family: 'Matter';
    src: url("${MatterMedium}")  format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Matter';
    src: url("${MatterSemiBold}")  format('woff2');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Matter';
    src: url("${MatterBold}")  format('woff2');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Matter';
    src: url("${MatterHeavy}")  format('woff2');
    font-weight: 600;
  }
`;

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

//data -> graphql query response
//children -> child componenets
const DefaultLayout = ({ data, children }) => {
  const headerImg = data.loadHeaderImg.childImageSharp.fluid;
  const scrollToElement = elementID => {
    let rect = document.getElementById(elementID).getBoundingClientRect();
    window.scrollTo(0, rect.top);
  };
  return (
    <>
      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <BackgroundImage
            Tag="header"
            className="site-head"
            fluid={headerImg}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <GlobalStyle />
            <div className="container" id="home">
              <div className="site-mast">
                <div className="site-mast-left">
                  <OutboundLink href="https://www.jam3.com" target="_blank" rel="noopener noreferrer">
                    <img className="site-logo" src={jamLogo} alt="Dev@Jam3" />
                  </OutboundLink>
                </div>
                <div className="site-mast-right">
                  <OutboundLink
                    href="https://github.com/Jam3?type=source"
                    className="site-nav-item first anim-word-first"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Github
                  </OutboundLink>
                  <OutboundLink
                    href="https://www.npmjs.com/org/jam3"
                    className="site-nav-item second anim-word-first"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NPM
                  </OutboundLink>
                  <OutboundLink
                    className="site-nav-item last anim-word-first"
                    href="https://medium.com/@Jam3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Medium
                  </OutboundLink>
                  <OutboundLink
                    href="https://www.jam3.com"
                    className="site-nav-item first anim-word-first"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jam3.com
                  </OutboundLink>
                </div>
              </div>
              <div className="site-banner animation-disabled">
                <h4 className="site-banner-title no-anim">Jam3 Developers</h4>
                <h1 className="site-banner-desc no-anim">
                  Explore our work and our contributions to the open source community
                </h1>
              </div>

              <div className="site-banner animation-enabled">
                <div>
                  <div className="site-banner-title-wrap">
                    <span className="site-banner-title first anim-word-first">Jam3</span>
                    <span className="site-banner-title second anim-word-first">Developers</span>
                  </div>
                  <div className="site-banner-row row-1">
                    <span className="site-banner-desc first">Explore</span>
                    <span className="site-banner-desc first">our</span>
                    <span className="site-banner-desc first">work</span>
                    <span className="site-banner-desc first">and</span>
                    <br></br>
                  </div>
                  <div className="site-banner-row row-2">
                    <span className="site-banner-desc second">our</span>
                    <span className="site-banner-desc second">contributions</span>
                    <span className="site-banner-desc second">to</span>
                    <span className="site-banner-desc second">the</span>
                    <br></br>
                  </div>
                  <div className="site-banner-row row-3">
                    <span className="site-banner-desc third">open</span>
                    <span className="site-banner-desc third">source</span>
                    <span className="site-banner-desc third">community</span>
                    <span className="site-banner-desc third"></span>
                    <br></br>
                  </div>
                  <div className="site-banner-row row-mask"></div>
                </div>
              </div>

              {/* Navigation bar, smooth scrolls to each section (projects, articles, labs)

                            <nav className="site-nav">
                                <div className="site-nav-left">
                                    The navigation items as setup in Ghost
                                    <Navigation data={site.navigation} navClass="site-nav-item" />  dynamic navbar based on graphql settings
                                    <p
                                        className="site-nav-item first"
                                        onClick={() => scrollToElement('projects-container')}
                                    >
                                        Projects
                                    </p>
                                    <p
                                        className="site-nav-item second"
                                        onClick={() => scrollToElement('articles-container')}
                                    >
                                        Articles
                                    </p>
                                    <p className="site-nav-item last" onClick={() => scrollToElement('labs-container')}>
                                        Labs
                                    </p>
                                </div>
                            </nav> */}
            </div>
          </BackgroundImage>
          <main role="main" className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            {children}
          </main>
        </div>

        <div className="viewport-bottom">
          {/* The footer at the very bottom of the screen */}
          <footer role="contentinfo" className="site-foot">
            <div className="site-foot-nav container">
              <div className="site-foot-nav-left">
                <OutboundLink href="https://www.jam3.com" target="_blank" rel="noopener noreferrer">
                  <img className="site-foot-logo" src={jamLogoStatic} alt="Dev@Jam3" />
                </OutboundLink>
              </div>
              <div className="site-foot-nav-center">
                {/* TODO, fix icons */}
                {FOOTER_LINKS.map((link, index) => (
                  <OutboundLink
                    key={`${index}-${link.alt}`}
                    href={link.src}
                    className="site-footer-item"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="site-foot-icon" src={link.img} alt={link.alt} />
                  </OutboundLink>
                ))}
              </div>
              <div className="site-foot-nav-right">
                <UpArrowSVG className="site-foot-scrollup" onClick={() => scrollToElement('home')}></UpArrowSVG>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool
};

//query for image processing on the header image
//GatsbyImageSharpFluid enables "blur up" loading
const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        loadHeaderImg: file(relativePath: { eq: "header-splash.png" }) {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
);

export default DefaultLayoutSettingsQuery;
